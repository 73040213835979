<template>
    <div class="account-router flex">
        <div class="left">
            <div :class="['card', ]">
                <div class="header" :class="getVipBgClass()">
                    <!-- <img class="avatar-image" :src="userInfo.avatar" alt="avatar"/> -->
                    <el-avatar :size="60" :src="userInfo.avatar" icon="el-icon-user" class="fonts-36"></el-avatar>
                    <div class="margin-t-10 fonts-14">{{userInfo.nickname || userInfo.username || userInfo.phone}}</div>
                    <div class="absolute zindex-10" style="bottom:35px;left:120px;">
                        <vip-icon :size="20"></vip-icon>
                    </div>
                </div>
                <div class="nav-list">
                    <template v-for="(item,index) in navList">
                    <div :class="['item',key===item.link?'active':'']" :key="index" v-permission="item.permission">
                        <span class="margin-r-10 fonts-18"><svg-icon :name="item.icon"></svg-icon></span>
                        <router-link :to="item.link">{{item.name}}</router-link>
                    </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="right flex-1">
            <router-view :key="key"></router-view>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import BgProgreess from "@/components/BgProgreess";
import VipIcon from "@/components/icon/VipIcon";
export default {
    name: 'AccountRouter',
    components: { VipIcon, BgProgreess },
    data(){
        return{
            navList:[
               {icon:'ib-diamond-o', name:'我的会员', link: '/account/vip', permission:'account:VIPRouter'},
               {icon:'ib-user-o', name:'我的账户', link: '/account/index', permission:'account'},
               {icon:'ib-center', name:'企业信息', link: '/account/orgInfo', permission:'account:orgInfoRouter'},
               {icon:'ib-token', name:'智能体Token', link: '/account/agentToken', permission:''},
               {icon:'ib-doc-o', name:'我的订单', link: '/account/orders', permission:'account:ordersRouter'},
               {icon:'ib-polyline', name:'流量监测', link: '/account/charHistory', permission:'account:charHistoryRouter'},
               {icon:'ib-invite', name:'邀请好友', link: '/account/invite', permission:'account:inviteRouter'}
            ]
        }
    },
    computed: {
        ...mapGetters({
            userData: 'user/userInfo',
        }),
        ...mapState({
			userInfo: state => state.user.userData,
		}),
        key() {
           return this.$route.path==='/account'?'/account/index':this.$route.path
        },
    },
    created(){
        this.$store.dispatch('user/queryUserInfoAction');
    },
    methods: {
        getVipBgClass(){
            if(this.userInfo){
                if(['pro-trial', 'pro-internal', 'pro', 'pro-beta', 'internal'].indexOf(this.userInfo.accountType) > -1){
                    return 'vip-pro-bg';
                }else if(['personal', 'pro-limited'].indexOf(this.userInfo.accountType) > -1){
                    return 'vip-free-bg';
                }else if(['enterprise', 'STANDARD', 'TRIAL'].indexOf(this.userInfo.accountType) > -1){
                    return 'vip-nterprise-bg';
                }else if(['ULTIMATE'].indexOf(this.userInfo.accountType) > -1) {
                    return 'vip-ultimate-bg';
                }
            }
        }
    },
    mounted(){

    },
}
</script>
<style lang="scss" scoped>
.account-router{
    width: 1275px;
    margin: 20px auto;
    background-color: #ffffff;
    border-radius: 10px;
    .left{
        width: 220px;
        .card{
            height: 100%;
            border-radius: 10px 0 0 10px;
            border: none;
            .header {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 18px 20px;
                border-radius: 10px 0 0 0;
            }
        }
        .nav-list{
            .item{
                padding: 15px 40px;
                border-left:4px solid transparent;
                box-sizing: border-box;
                color: #666666;
                a{
                    color: #666666;
                }
                &.active{
                    color: #000000;
                    a{
                        color: #000000;
                    }
                    border-color: #00D959;
                } 
            }
        }
    }
    
    .right{
        // margin-left: 30px;
        box-sizing: border-box;
        border-left: 1px solid #E4E4E4;
    }
    .avatar-image{
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }
    .vip-free-bg{
        background: linear-gradient(180deg, #E4E4E4 0%, rgba(248, 248, 248, 0) 100%);
    }
    .vip-pro-bg{
        background: linear-gradient(180deg, #F2D38F 0%, rgba(255, 228, 248, 0) 100%);
    }
    .vip-nterprise-bg{
        background: linear-gradient(180deg, #BFA7DC 0%, rgba(241, 219, 212, 0) 100%);
    }
    .vip-ultimate-bg{
        background: linear-gradient(180deg, #75A7F2 0.33%, rgba(65, 137, 245, 0.00) 98.87%);


    }
}
</style>
