<template>
    <div class="bg-progreess-component">
        <div class="rate" :style="{'width': (percentage < 0 ? 0 : percentage > 100 ? 100 : percentage) + '%'}"></div>
        <div class="content">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    name: "BgProgreess",
    props: {
        percentage: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {

        }
    },
    methods: {

    }
}
</script>
<style lang="scss" scoped>
.bg-progreess-component{
    position: relative;
    background-color: #F8F8F8;
    overflow: hidden;
    border-radius: 6px;
    min-height: 1px;
    > .rate {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background-color: #D3F4EC;
        width: 0;
        transition: width 0.2s linear;
    }
    > .content {
        height: 100%;
        position: relative;
    }
}
</style>
